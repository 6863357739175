.prodv-img {
	@media (max-width: $below-small) {
		top: 0;
		left: auto;
		right: 0;
		width: calc(100% - 210px);
	}
	@media (max-width: $below-tablet) {
		position: relative;
		width: 100%;
		top: auto;
		right: auto;
		margin: 0;
	}
	.product-image {
		display: none;
		&.is-active {
			display: block;
		}
		@media (max-width: $below-tablet) {
		  height: 245px!important;
		}
	}
	.threesixty_images {
		@media (max-width: $below-small) {
			position: relative;
			top: 13%;
		}
		@media (max-width: $below-tablet) {
			top: 0;
		}
	}
}

.product-cards__item {
	text-align: center;
	padding: 0 10px;
}

.product-cards__img {
	margin: 0 auto 15px;
	width: 100%;
	max-width: 150px;
}

.cards-carousel__btn {
	color: #868C9F;
	border: none;
	background: 0;
	position: absolute;
	top: 40%;
	font-size: 45px;
	padding: 0 15px;
	margin-top: -25px;
	cursor: pointer;
	&.btn--next {
		right: -10px;
	}
	&.btn--prev {
		left: -10px;
	}
}

.sl-360-img {
	position: absolute;
	width: 100px;
	right: 75px;
	bottom: 100px;
	@media (max-width: $below-small) {
		position: relative;
		right: -30px;
  	bottom: 25px;
	}
	@media (max-width: $below-tablet) {
		right: auto;
		bottom: auto;
		display: block;
		margin: 0 auto 20px;
	}
}

.product {
	.prodv-add {
		@media (max-width: $below-small) {
			position: relative;
			width: 160px;
			right: auto;
			bottom: auto;
		}
		@media (max-width: $below-tablet) {
		  margin-bottom: 0;
		}
	}
}

.product-vc-container {
	padding: 60px 0;
}
