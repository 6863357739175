/* ------------------------------------
 *  Width and Height
 * ------------------------------------ */
$inner-wrap-l: 1260px;
$inner-wrap-m: 1260px;
$inner-wrap-s: 550px;

/* ------------------------------------
 *  Color
 * ------------------------------------ */
$primary-color: #000000;
$pink: #f0566f;
$purple: #5450a2;
$dark-blue: #131523;
$pink-red: #ee3d54;

$gray: #b4b4b4;
$gray-dark: #777;
$gray-light: #eeeeed;

$white-light: #f4f4f4;

$red: #DA0000;
$radius: 4px;

/* ------------------------------------
 *  Font
 * ------------------------------------ */
$primary-font: "Inconsolata", "Helvetica Neue", "Helvetica", "Arial", "source-han-sans-traditional", "PingFang TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
$secondary-font: "Cabin", sans-serif;

/* ------------------------------------
 *  Gutter
 * ------------------------------------ */
$block-gutter-l: 70px;
$block-gutter-m: 30px;
$block-gutter-s: 20px;
$page-gutter: 40px;
$page-gutter-s: 20px;

$article-gutter: 60px;

$bold: 700;
$nav-height-l: 80px;
$nav-height-s: 50px;
