.inline-block {
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
	&__item {
		display: inline-block;
		vertical-align: middle;
		padding: 0 8px;
	}
}

.inline-block--center {
	text-align: center;
}

.hide {
	display: none;
}

.bg-white {
	background-color: #fff;
}

.two-column-count {
	column-count: 2;
}
