@define-mixin clear {
	&:before, &:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@define-mixin hover-color $color {
	transition: color .2s;
	@media (min-width: $above-desktop) {
		&:hover {
			color: $color;
		}
	}
}

@define-mixin hover-color-darken $color, $percentage{
	&:hover {
		color: color($color blackness($percentage));
	}
}

@define-mixin hover-bg-color $bgcolor, $color {
	&:hover {
		background-color: $bgcolor;
		color: $color;
	}
	transition: background-color .2s, color .2s;
}

@define-extend bg-cover {
	background-size: cover;
	background-position: 50% 50%;
}

/* typrography */
@define-mixin font-weight $english, $chinese {
	.lang-en & {
		font-weight: $english;
	}
	.lang-tw & {
		font-weight: $chinese;
	}
}

@define-mixin font-size $english, $chinese {
	.lang-en & {
		font-size: $english;
	}
	.lang-tw & {
		font-size: $chinese;
	}
}

@define-mixin line-height $english, $chinese {
	.lang-en & {
		line-height: $english;
	}
	.lang-tw & {
		line-height: $chinese;
	}
}

@define-mixin letter-spacing $english, $chinese {
	.lang-en & {
		letter-spacing: $english;
	}
	.lang-tw & {
		letter-spacing: $chinese;
	}
}
